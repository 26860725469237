import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import Layout from './components/Layout';

import { Project } from './project/Project';
import { AppPages, StoreKeys } from './project/Defines';
import { Template } from './pages/Template';
import { useDispatch, connect } from 'react-redux';
import { menuItems } from './components/Navigation/Menu';
import { Ajax } from './components/Ajax';

import Login from './pages/Login';
import LoginCallback from './pages/LoginCallback';
import WarrantyRequestActions from './pages/WarrantyRequestActions';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import Brand from './pages/Brand';
import BrandDetails from './pages/Brand/detail';
import SalesNetwork from './pages/SalesNetwork';
import Universe from './pages/Universe';
import UniverseDetails from './pages/Universe/detail';
import BillOfMaterials from './pages/BillOfMaterials';
import BillOfMaterialDetails from './pages/BillOfMaterials/detail';
import SparePart from './pages/SparePart';
import SparePartDetails from './pages/SparePart/detail';
import Product from './pages/Product';
import ProductDetails from './pages/Product/detail';
import SalesNetworkDetails from './pages/SalesNetwork/detail';
import Services from './pages/Services';
import Process from './pages/Process';
import ProcessDetails from './pages/Process/detail';
import Charts from './pages/Process/charts';
import User from './pages/User';
import UserDetails from './pages/User/detail';
import SocieteUserDetails from './pages/User/SocieteUser/detail';

import PanierExterne from './pages/PanierExterne';
import PanierMessage from './pages/PanierExterne/Message';
import BillOfMaterialsExtract from './pages/BillOfMaterials/extract';
import SetupParameters from './pages/Setup/ProductParameters/index.js';
import SetupProductParameterDetails from './pages/Setup/ProductParameters/detail';
import SetupClientTypes from './pages/Setup/ClientTypes/index';
import SetupClientTypeDetails from './pages/Setup/ClientTypes/detail';
import SetupWarrantyTypes from './pages/Setup/WarrantyTypes/index';
import SetupCountries from './pages/Setup/Countries/index';
import SetupCountryDetails from './pages/Setup/Countries/detail';
import SetupWarrantyTypeDetails from './pages/Setup/WarrantyTypes/detail';
import SetupDefects from './pages/Setup/Defects/index';
import SetupDefectDetails from './pages/Setup/Defects/detail';
import SetupArticleTypes from './pages/Setup/ArticleTypes/index';
import SetupArticleTypeDetails from './pages/Setup/ArticleTypes/detail';
import SetupVideos from './pages/Setup/Videos/index';
import SetupVideoDetails from './pages/Setup/Videos/detail';
import SetupNorms from './pages/Setup/Norms/index';
import SetupNormDetails from './pages/Setup/Norms/detail';
import SetupRechercheNoSerie from './pages/Setup/RechercheNoSerie/index';
import SetupRechercheNoSerieDetails from './pages/Setup/RechercheNoSerie/detail';
import SetupTranslation from './pages/Setup/Translation/index';
import SetupLanguages from './pages/Setup/Languages/index';
import SetupLanguageDetails from './pages/Setup/Languages/detail';
import SalesOrder from './pages/Jobs/SalesOrder';
import SalesOrderDetails from './pages/Jobs/SalesOrder/detail';
import WarrantyRequest from './pages/Jobs/WarrantyRequest';
import WarrantyRequestDetails from './pages/Jobs/WarrantyRequest/detail';
import Devis from './pages/Jobs/Devis';
import Administrators from './pages/Administration/Administrators';
import AdministratorsDetails from './pages/Administration/Administrators/detail';
import AllowedIP from './pages/Administration/AllowedIP';
import AllowedIPDetails from './pages/Administration/AllowedIP/detail';
import BankCard from './pages/Administration/BankCard';
import BankCardDetails from './pages/Administration/BankCard/detail';
import ReseauConfigCBS from './pages/Administration/ReseauConfigCBS';
import ReseauConfigCBSDetails from './pages/Administration/ReseauConfigCBS/detail';
import APIAccess from './pages/Administration/APIAccess';
import APIAccessDetails from './pages/Administration/APIAccess/detail';
import Applications from './pages/Administration/Applications';
import TVADetails from './pages/Administration/TVA/detail';
import TVA from './pages/Administration/TVA';
import SSODetails from './pages/Administration/SSO/detail';
import SSO from './pages/Administration/SSO';
import MaintenanceDetails from './pages/Administration/Maintenance/detail';
import Maintenance from './pages/Administration/Maintenance';
import History from './pages/Administration/History';
import HistoryDetails from './pages/Administration/History/detail';
import SetupCountryGrouping from './pages/Setup/CountryGrouping';
import SetupCountryGroupingDetails from './pages/Setup/CountryGrouping/detail';
import SparePartImagesUpload from './pages/SparePart/SparePartImages/detail';
import { storageChange, changeApplicationToken, changeUserToken } from './store/actions';
import ConnectivityBlocking from './pages/Administration/ConnectivityBlocking';
import UsersSiretChanges from './pages/User/SiretChange/detail';
import Skills from './pages/Administration/Skills';
import SkillsDetails from './pages/Administration/Skills/detail';
import Fees from './pages/Administration/Fees';
import FeeDetails from './pages/Administration/Fees/detail';
import DefectCode from './pages/Administration/DefectCode';
import DefectCodeDetails from './pages/Administration/DefectCode/detail';
import DocumentView from './pages/DocumentView';
import Coverage from './pages/Administration/Coverage';
import CoverageDetails from './pages/Administration/Coverage/detail';
import Settings from './pages/Administration/Settings';
import { initLanguages } from './Shared/Translation';

const routings = [{ page: AppPages.SetupProductParameter, component: SetupParameters },
//{ page: AppPages.Login, component: Login },
{ page: AppPages.Logout, component: Logout },
{ page: AppPages.Template, component: Template },
{ page: AppPages.Brand, component: Brand },
{ page: AppPages.BrandDetails, component: BrandDetails, routeParams: ':id' },
{ page: AppPages.SalesNetwork, component: SalesNetwork },
{ page: AppPages.SalesNetworkDetails, component: SalesNetworkDetails, routeParams: ':id' },
{ page: AppPages.Universe, component: Universe },
{ page: AppPages.UniverseDetails, component: UniverseDetails, routeParams: ':id' },
{ page: AppPages.Products, component: Product },
{ page: AppPages.ProductDetails, component: ProductDetails, routeParams: ':id' },
{ page: AppPages.SparePartImagesUpload, component: SparePartImagesUpload },
{ page: AppPages.SpareParts, component: SparePart },
{ page: AppPages.SparePartDetails, component: SparePartDetails, routeParams: ':id' },
{ page: AppPages.BillOfMaterialExtract, component: BillOfMaterialsExtract },
{ page: AppPages.BillOfMaterials, component: BillOfMaterials },
{ page: AppPages.BillOfMaterialDetails, component: BillOfMaterialDetails, routeParams: ':id' },
{ page: AppPages.User, component: User },

{ page: AppPages.UserDetails, component: UserDetails, routeParams: ':id' },
{ page: AppPages.SocieteUserDetails, component: SocieteUserDetails, routeParams: [':societe_uuid', ':id'] },
{ page: AppPages.UsersSiretChanges, component: UsersSiretChanges },
{ page: AppPages.SetupProductParameter, component: SetupParameters },
{ page: AppPages.SetupProductParameterDetails, component: SetupProductParameterDetails, routeParams: ':id' },
{ page: AppPages.SetupClientTypeDetails, component: SetupClientTypeDetails, routeParams: ':id' },
{ page: AppPages.SetupClientTypes, component: SetupClientTypes },
{ page: AppPages.SetupArticleTypeDetails, component: SetupArticleTypeDetails, routeParams: ':id' },
{ page: AppPages.SetupArticleTypes, component: SetupArticleTypes },
{ page: AppPages.SetupProductDefectDetails, component: SetupDefectDetails, routeParams: ':id' },
{ page: AppPages.SetupProductDefect, component: SetupDefects },
{ page: AppPages.SetupVideoDetails, component: SetupVideoDetails, routeParams: ':id' },
{ page: AppPages.SetupVideo, component: SetupVideos },
{ page: AppPages.SetupWarrantyTypeDetails, component: SetupWarrantyTypeDetails, routeParams: ':id' },
{ page: AppPages.SetupWarrantyTypes, component: SetupWarrantyTypes },
{ page: AppPages.SetupNormDetails, component: SetupNormDetails, routeParams: ':id' },
{ page: AppPages.SetupNormes, component: SetupNorms },
{ page: AppPages.SetupCountryGroupingDetails, component: SetupCountryGroupingDetails, routeParams: ':id' },
{ page: AppPages.SetupCountryGrouping, component: SetupCountryGrouping },
{ page: AppPages.SetupCountries, component: SetupCountries },
{ page: AppPages.SetupCountryDetails, component: SetupCountryDetails, routeParams: ':id' },
{ page: AppPages.SetupRechercheNoSerie, component: SetupRechercheNoSerie },
{ page: AppPages.SetupRechercheNoSerieDetails, component: SetupRechercheNoSerieDetails, routeParams: ':id' },
{ page: AppPages.SetupTranslation, component: SetupTranslation },
{ page: AppPages.SetupLanguages, component: SetupLanguages },
{ page: AppPages.SetupLanguageDetails, component: SetupLanguageDetails, routeParams: ':id' },
{ page: AppPages.JobsSalesOrder, component: SalesOrder },
{ page: AppPages.JobsSalesOrderDetails, component: SalesOrderDetails, routeParams: ':id' },
{ page: AppPages.JobsWarrantyRequest, component: WarrantyRequest },
{ page: AppPages.JobsWarrantyRequestDetails, component: WarrantyRequestDetails, routeParams: ':id' },
{ page: AppPages.JobsDevis, component: Devis },
{ page: AppPages.Administrators, component: Administrators },
{ page: AppPages.AdministratorsDetails, component: AdministratorsDetails, routeParams: ':id' },
{ page: AppPages.AllowedIP, component: AllowedIP },
{ page: AppPages.AllowedIPDetails, component: AllowedIPDetails, routeParams: ':id' },
{ page: AppPages.BankCard, component: BankCard },
{ page: AppPages.BankCardDetails, component: BankCardDetails, routeParams: ':id' },
{ page: AppPages.ReseauConfigCBS, component: ReseauConfigCBS },
{ page: AppPages.ReseauConfigCBSDetails, component: ReseauConfigCBSDetails, routeParams: ':id' },
{ page: AppPages.APIAccess, component: APIAccess },
{ page: AppPages.APIAccessDetails, component: APIAccessDetails, routeParams: ':id' },
{ page: AppPages.Applications, component: Applications },
{ page: AppPages.TVA, component: TVA },
{ page: AppPages.TVADetails, component: TVADetails, routeParams: ':id' },
{ page: AppPages.History, component: History },
{ page: AppPages.HistoryDetails, component: HistoryDetails, routeParams: ':id' },
{ page: AppPages.ConnectivityBlocking, component: ConnectivityBlocking },
{ page: AppPages.Skills, component: Skills },
{ page: AppPages.SkillsDetails, component: SkillsDetails, routeParams: ':id' },
{ page: AppPages.Fees, component: Fees },
{ page: AppPages.FeeDetails, component: FeeDetails, routeParams: ':id' },
{ page: AppPages.DefectCode, component: DefectCode },
{ page: AppPages.DefectCodeDetails, component: DefectCodeDetails, routeParams: ':id' },
{ page: AppPages.ProcessDetails, component: ProcessDetails, routeParams: ':id' },
{ page: AppPages.Process, component: Process },
{ page: AppPages.SSO, component: SSO },
{ page: AppPages.SSODetails, component: SSODetails, routeParams: ':id' },
{ page: AppPages.Maintenance, component: Maintenance },
{ page: AppPages.MaintenanceDetails, component: MaintenanceDetails, routeParams: ':id' },
{ page: AppPages.Charts, component: Charts },
{ page: AppPages.DocumentView, component: DocumentView },
{ page: AppPages.Coverage, component: Coverage },
{ page: AppPages.CoverageDetails, component: CoverageDetails, routeParams: ':id' },
{ page: AppPages.Settings, component: Settings },
];


/**
*  the Base component that also manages routing
 * @module App
 */
function App(props) {
    const { role, isLoggedIn, storageChange, appToken } = props;
    const loading = <div style={{ "margin": "25% auto" }}><Spin size="large" /></div>;
    const homeRedirect = Project.getPageUrl(menuItems.find(i => Project.hasRoleAccess(role, i.to))?.to);
    const [showPages, setShowPages] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        const handler = e => {
            const trackingKeys = [StoreKeys.UserToken, StoreKeys.UserName, StoreKeys.LoggedIn, StoreKeys.ListStates, StoreKeys.Culture, StoreKeys.Role, StoreKeys.ExpireTime]
            if (trackingKeys.includes(e.key)) {
                storageChange();
            }
        };

        setTimeout(() => {
            if (isLoggedIn) {
                Ajax.post({
                    url: "/api/account/check",
                    data: {},
                    success: function (response) {
                        if (response) {
                            setShowPages(true);
                        }
                    }
                })
            } else {
                setShowPages(true);
            }
        }, 100);

        initLanguages();


        setInterval(()=>{
            refreshAppToken();
            refreshUserToken();
        }, 500);


        window.addEventListener('storage', handler);
        return () => { window.removeEventListener('storage', handler) }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const refreshAppToken = useCallback(() => {  // NOSONAR
        const _appToken = Project.getLocalStorage("appToken");
        const isLoadingAppToken = Project.getLocalStorage("isLoadingAppToken");
        if (!_appToken && !isLoadingAppToken){
            Project.setLocalStorage("isLoadingAppToken", true, 10);

            let fd =  "grant_type=client_credentials";
            let headers = { "Content-Type": "application/x-www-form-urlencoded" };
            const fetchOptions = { 
                method: "POST",
                body: fd
            };
            let params = {
                ...fetchOptions, 
                headers: headers
            };

            fetch(`${process.env.REACT_APP_API_TOKEN_URL ?? window.location.origin}/api/token`, params)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response && response.access_token){ // NOSONAR
                    Project.setLocalStorage("appToken", response, response.expires_in - 10);
                    dispatch(changeApplicationToken(response.access_token));
                    Project.setLocalStorage("isLoadingAppToken", false, 30);
                    window.location.reload();
                }
                Project.setLocalStorage("isLoadingAppToken", false, 30);
            })
            .catch((error) =>{
                console.log(error);
            });

        }
    },[dispatch]);// eslint-disable-line react-hooks/exhaustive-deps

    const refreshUserToken = useCallback(() => { // NOSONAR
        const isLoadingUserToken = Project.getLocalStorage("isLoadingUserToken");
        if (!isLoadingUserToken && isLoggedIn){
            Project.getLocalStorage("userToken", (expireData) => {
                Project.setLocalStorage("isLoadingUserToken", true, 30);
                const callback = `${window.location.origin}/wso/callback`;

                let fd = "";
                fd += "grant_type=refresh_token";
                fd += "&redirect_uri=" + callback;
                fd += "&refresh_token=" + expireData.refresh_token;
                let headers = { "Content-Type": "application/x-www-form-urlencoded" };
                const fetchOptions = { 
                    method: "POST",
                    body: fd
                };
                let params = {
                    ...fetchOptions, 
                    headers: headers
                };
    
                fetch(`${process.env.REACT_APP_API_TOKEN_URL ?? window.location.origin}/api/token`, params)
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    if (response && response.access_token){ // NOSONAR
                        Project.setLocalStorage("userToken", response, response.expires_in - 600);
                        //Project.setLocalStorage("userToken", response, 60) // NOSONAR
                        dispatch(changeUserToken(response.access_token));
                        Project.setLocalStorage("isLoadingUserToken", false, 30);
                        window.location.reload();
                    }
                    Project.setLocalStorage("isLoadingUserToken", false, 30);
                })
                .catch((error) =>{
                    console.log(error);
                });
                
            });
            
        }
    },[isLoggedIn, dispatch]);// eslint-disable-line react-hooks/exhaustive-deps

    if (!appToken)
        return <>{loading}</>;

    if (!isLoggedIn) {
        return (
            <Suspense fallback={loading}>
                <Layout>
                    <Switch>
                        <Route
                            path="/login"
                            component={Login}
                        />
                        <Route
                            path="/wso/callback"
                            component={LoginCallback}
                        />
                        <Route
                            path="/api/WarrantyRequest/:action/:id/:hash"
                            component={WarrantyRequestActions}
                        />
                        <Route
                            path="/panier_externe/:universe?/commande/:message?/:id/:ref"
                            component={PanierMessage}
                        />
                        <Route
                            path="/panier_externe/:universe?/:reference/:salt"
                            component={PanierExterne}
                        />
                        <Route path='/template' component={Template} />
                        <Route path="*" >
                            <Redirect to={Project.getPageUrl(AppPages.Login, "", { redirectUrl: window.location.pathname })} />
                        </Route>
                    </Switch>
                </Layout>
            </Suspense>)
    }

    if (!showPages)
        return <>{loading}</>;

    return (

        <Suspense fallback={loading}>
            <Layout>
                <Switch>
                    <Route exact path='/' >
                        {(homeRedirect && <Redirect to={homeRedirect} />) || <Redirect to={Project.getPageUrl(AppPages.Login)} />}
                    </Route>
                    <Route path="/login">
                        {(homeRedirect && <Redirect to={homeRedirect} />) || <Redirect to={Project.getPageUrl(AppPages.Login)} />}
                    </Route>
                    <Route
                        path="/api/WarrantyRequest/:action/:id/:hash"
                        component={WarrantyRequestActions}
                    />
                    {routings.map(r =>
                        <Route key={r.pages ? r.pages.join() : r.page} path={r.pages ? r.pages.map(p => Project.getPageUrl(p.page || p, p.routeParams)) : Project.getPageUrl(r.page, r.routeParams)} component={r.component} />)}

                    <Route
                        path="/panier_externe/:universe?/commande/:message?/:id/:ref"
                        component={PanierMessage}
                    />
                    <Route
                        path="/panier_externe/:universe?/:reference/:salt"
                        component={PanierExterne}
                    />
                    <Route path='/services' component={Services} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </Layout>
        </Suspense>

    );
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, role: state.role, appToken: state.appToken }),
    dispatch => ({
        storageChange: (key) => dispatch(storageChange())
    }))(App);
